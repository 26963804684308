import React from 'react'
import { createUseStyles } from 'react-jss'
import { CaptionProps } from '../types/types'

const useStyles = createUseStyles({
  title: {
    fontStyle: 'italic',
  },
  subtitle: {
    fontStyle: 'italic',
  },
})

const Caption = ({ activeWork }: CaptionProps) => {
  const classes = useStyles()
  return (
    <div>
      <div className={classes.title}>{activeWork.title}</div>
      <div className={classes.subtitle}>{activeWork.subtitle}</div>
      <div>{activeWork.medium}</div>
      <div>{activeWork.dimensions}</div>
    </div>
  )
}

export default Caption
