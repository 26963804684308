import Email from './Email'
import { createUseStyles } from 'react-jss'

import { HeaderProps } from '../types/types'

const useStyles = createUseStyles({
  header: {
    // position: 'absolute',
    // top: -28,
    // left: 20,
    // display: 'flex',
    // flexFlow: 'row wrap',
  },
  name: {
    //   margin: '0 1rem 0 0',
  },
})

const Header = ({ siteName, email }: HeaderProps) => {
  const classes = useStyles()

  return (
    <div className={classes.header}>
      <div className={classes.name}>{siteName}</div>
      <Email email={email} />
    </div>
  )
}

export default Header
