import Header from './Header'
import Caption from './Caption'
import Compass from './Compass'
import Control from './Control'

import { createUseStyles } from 'react-jss'

import { SidebarProps } from '../types/types'

const useStyles = createUseStyles({
  sidebar: {
    position: 'relative',
    margin: '0 16px',
    padding: '0 5% 10px 0',
    '@media only screen and (min-width: 768px)': {
      slide: {
        float: 'left',
        width: '25%',
        // padding: '0px 30% 0 0',
      },
      '& > div': {
        paddingBottom: '1rem',
      },
    },
  },
})

const Sidebar = ({
  siteName,
  email,
  activeWork,
  index,
  total,
  changeIndex,
}: SidebarProps) => {
  const classes = useStyles()

  return (
    <div className={classes.sidebar}>
      <Header siteName={siteName} email={email} />
      <Caption activeWork={activeWork} />
      <Compass index={index} total={total} />
      <Control changeIndex={changeIndex} index={index} total={total} />
    </div>
  )
}

export default Sidebar
