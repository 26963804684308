import { CompassProps } from '../types/types'

const Compass = ({ index, total }: CompassProps) => {
  return (
    <div>
      {index + 1} of {total}
    </div>
  )
}

export default Compass
