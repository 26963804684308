import { StrictMode, useState } from 'react'

import Slide from './Slide'
import Sidebar from './sidebar/Sidebar'

import { createUseStyles } from 'react-jss'

import getJson from './local_modules/get-json'

import { Change } from './types/types'

const worksJson = '/works.json'
const siteName = 'Brian Dehart'
const email = 'brian@briandehart.com'

const doFetch = getJson(worksJson)

const useStyles = createUseStyles({
  topContainer: {
    position: 'relative',
    width: '100%',
    margin: 0,
    padding: 0,
    font: '12px/20px Georgia, "Times New Roman", Times, serif',
    color: '#666666',
    maxHeight: '50%',
  },
  '@media only screen and (min-width: 768px)': {
    topContainer: {
      width: '100%',
      margin: '0 auto',
      padding: '40px 0 0 10%',
      font: '14px/22px Georgia, "Times New Roman", Times, serif',
    },
  },
  '@media only screen and (min-width: 1100px)': {
    topContainer: {
      width: 1026,
      margin: '0 auto',
      padding: '40px 0 0 20%',
    },
  },
})

function App() {
  const works = doFetch()
  const classes = useStyles()

  const [state, setState] = useState({
    index: 0,
    work: works[0],
  })

  const changeIndex = (change: Change) => {
    let i = state.index
    if (change === 'back') i = 0
    if (change === 'next' && i < works.length - 1) i = i + 1
    if (change === 'prev' && i > 0) i = i - 1
    setState({ index: i, work: works[i] })
  }

  return (
    <div className={classes.topContainer}>
      <StrictMode>
        <Slide src={state.work.src} changeIndex={changeIndex} />
        <Sidebar
          siteName={siteName}
          email={email}
          activeWork={state.work}
          index={state.index}
          total={works.length}
          changeIndex={changeIndex}
        />
      </StrictMode>
    </div>
  )
}

export default App
