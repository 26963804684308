import { createUseStyles } from 'react-jss'

const loaderHeight = 10

const useStyles = createUseStyles({
  '@keyframes fadeIn': {
    to: { opacity: 0.95 },
  },
  '@keyframes animFw': {
    '0%': {
      width: 0,
    },
    '100%': {
      width: '100%',
    },
  },
  loading: {
    position: 'fixed',
    zIndex: 5000,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    top: 0,
    left: 0,
    paddingTop: 200,
    height: '100%',
    width: '100%',
    padding: 0,
    margin: 0,
    backgroundColor: 'rgba(255, 255, 255, .75)',
    opacity: 0,
    animation: `0s linear 1s forwards $fadeIn`,
  },
  loader: {
    width: 104,
    height: loaderHeight,
    display: 'inline-block',
    position: 'relative',
    background: 'rgba(0, 0, 0, 0.25)',
    overflow: 'hidden',
    '&:after': {
      content: '""',
      boxSizing: 'border-box',
      width: 0,
      height: loaderHeight,
      background: 'rgba(0, 0, 0, 0.75)',
      position: 'absolute',
      top: 0,
      left: 0,
      animation: `$animFw 10s linear infinite`,
    },
  },
})

const Loading = () => {
  const classes = useStyles()

  return (
    <div className={classes.loading}>
      <div>Loading image...</div>
      <div className={classes.loader}></div>
    </div>
  )
}

export default Loading
