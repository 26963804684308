import imageCacher from './local_modules/imageCacher'

import { SuspenseImageProps } from './types/types'

const SuspenseImage = ({ src, ...rest }: SuspenseImageProps) => {
  // Suspense will hang if src is missing
  if (!src) {
    return null
  } else {
    imageCacher.read(src)
    // eslint-disable-next-line
    return <img src={src} {...rest} />
  }
}

export default SuspenseImage
