import { Works } from "../types/types";

const getJson = (resource: string) => {
  let status = "pending";
  let json: Works;

  const fetchingJson = (async function () {
    const response = await fetch(resource);
    json = await response.json();
    status = "resolved";
  })();

  return () => {
    if (status === "pending") throw fetchingJson;
    return json;
  };
};

export default getJson;
