import { createUseStyles } from 'react-jss'
import { EmailProps } from '../types/types'

const useStyles = createUseStyles({
  emailA: {
    '&:link': {
      color: '#666666',
      textDecoration: 'none',
      // borderBottom: '1px solid #666666'
    },
    '&:visited': {
      color: '#666666',
      textDecoration: 'none',
      // borderBottom: '1px solid #666666'
    },
    '&:hover': {
      color: '#666666',
      textDecoration: 'none',
      borderBottom: '1px solid #666666',
    },
    '&:active': {
      color: '#666666',
      textDecoration: 'none',
      // borderBottom: '1px solid #666666'
    },
  },
})

const Email = ({ email }: EmailProps) => {
  const classes = useStyles()
  const mailto = `mailto:${email}`
  return (
    <div>
      <a className={classes.emailA} href={mailto}>
        {email}
      </a>
    </div>
  )
}

export default Email
