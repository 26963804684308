import { useTransition } from 'react'
import { createUseStyles } from 'react-jss'
import { useSwipeable } from 'react-swipeable'
import Loading from './Loading'
import SuspenseImage from './SuspenseImage'

import { Direction, SlideProps } from './types/types'

const useStyles = createUseStyles({
  slide: {
    // position: 'relative',
    // width: '100%',
    margin: '20px 16px 0 16px',
    // padding: 0,
    // flexGrow: 1,
    // flexShrink: 99,
    // width: '100%',
  },
  image: {
    // width: '100%',
    // height: 'auto',
    maxWidth: '100%',
    // maxHeight: '70%', /* for small screens */
    // maxHeight: 800,
  },
  '@media only screen and (min-width: 768px)': {
    slide: {
      float: 'left',
      width: '56%',
      margin: 0,
      padding: '0 40px 0 0',
    },
  },
  '@media only screen and (min-width: 1100px)': {
    slide: {
      padding: '0 60px 0 0',
    },
  },
})

const Slide = ({ src, changeIndex }: SlideProps) => {
  const [isPending, startTransition] = useTransition()

  const handleSwipe = (dir: Direction) => {
    startTransition(() => {
      if (dir === 'left') changeIndex('next')
      else changeIndex('prev')
    })
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSwipe('left'),
    onSwipedRight: () => handleSwipe('right'),
  })

  const classes = useStyles()

  return (
    <>
      {isPending ? <Loading /> : null}
      <div {...handlers} className={classes.slide}>
        <SuspenseImage
          src={'./images/' + src}
          className={classes.image}
          alt="Artwork"
        />
      </div>
    </>
  )
}

export default Slide
