interface Cache {
  [index: string]: boolean | Promise<boolean>
}

interface ImageCacher {
  __cache: Cache
  read: (src: string) => void
}

const imageCacher: ImageCacher = {
  __cache: {},
  read(src: string) {
    if (!this.__cache[src]) {
      this.__cache[src] = new Promise((resolve) => {
        const img = new Image()
        img.onload = () => {
          this.__cache[src] = true
          resolve(this.__cache[src])
        }
        img.src = src
      })
      // what is the point of .then here?
      // .then((img) => {
      //   // why pass image here?
      //   this.__cache[src] = true
      // })
    }
    if (this.__cache[src] instanceof Promise) {
      throw this.__cache[src]
    }
    return this.__cache[src]
  },
}

export default imageCacher
