import { useTransition } from 'react'
import { createUseStyles } from 'react-jss'
import Loading from '../Loading'

import { Change, ControlProps } from '../types/types'

const useStyles = createUseStyles({
  control: {
    position: 'absolute',
    top: 0,
    right: 100,
    display: 'flex',
    flexFlow: 'row nowrap',
    // visibility: 'hidden',
    // border: '1px solid red',
    '& div': {
      cursor: 'pointer',
    },
    '& img': {
      filter:
        'invert(39%) sepia(2%) saturate(0%) hue-rotate(21deg) brightness(100%) contrast(88%)',
    },
    '@media only screen and (min-width: 768px)': {
      position: 'relative',
      // visibility: 'visible',
      top: 0,
      right: 0,
    },
  },
  previous: {
    position: 'absolute',
    left: '-12px',
  },
  next: {
    position: 'absolute',
    left: '24px',
  },
  back: {
    position: 'absolute',
    left: '64px',
  },
  image: {
    width: 36,
    height: 36,
    '@media only screen and (min-width: 768px)': {
      width: 36,
      height: 36,
    },
  },
  off: {
    opacity: 0.2,
    cursor: 'default',
  },
})

const Control = ({ changeIndex, index, total }: ControlProps) => {
  const [isPending, startTransition] = useTransition()

  const handleClick = (change: Change) => {
    startTransition(() => {
      changeIndex(change)
    })
  }

  const classes = useStyles()

  const width = '36px'
  const height = '36px'

  return (
    <>
      {isPending ? <Loading /> : null}
      <div className={classes.control}>
        <div className={classes.previous} onClick={() => handleClick('prev')}>
          <img
            className={
              index > 0 ? classes.image : classes.image + ' ' + classes.off
            }
            src="./images/chevron_left-black-48dp.svg"
            alt="Previous"
            width={width}
            height={height}
          />
        </div>
        <div className={classes.next} onClick={() => handleClick('next')}>
          <img
            className={
              index < total && index < total - 1
                ? classes.image
                : classes.image + ' ' + classes.off
            }
            src="./images/chevron_right-black-48dp.svg"
            alt="Next"
            width={width}
            height={height}
          />
        </div>
        <div className={classes.back} onClick={() => handleClick('back')}>
          <img
            className={classes.image}
            // className={index === total - 1 ? null : ' ' + classes.off}
            src="./images/first_page-black-48dp.svg"
            alt="Back to start"
            width={width}
            height={height}
          />
        </div>
      </div>
    </>
  )
}

export default Control
